import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Authentication from '../libraries/Authentication/Authentication'

import Error404 from '@/components/Core/LandingPage/Error404'
import Error412 from '@/components/Core/LandingPage/Error412'
import Error419 from '@/components/Core/LandingPage/Error419'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: Error404,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/404',
      component: Error404,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/412',
      component: Error412,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/419',
      component: Error419,
      meta: {
        requiresAuth: false
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // By default all routes are private and redirected to /login.
  // To make URL public, set 'meta { requiresAuth: false }'
  // To use hashed public URL, set 'meta: { requiresClientCredentialsAuth: true, requiresAuth: false }'

  if (!store.state.loggedIn) {
    if (to.matched.some(record => !record.meta.requiresAuth)) {
      if (to.matched.some(record => record.meta.requiresClientCredentialsAuth) && !store.getters.clientCredentialsLoggedIn) {
        const authentication = new Authentication()
        authentication.clientCredentialsLogin().then(function () {
          if (store.getters.clientCredentialsLoggedIn) {
            next()
          } else {
            next({ name: 'Login' })
          }
        })
      } else {
        next()
      }
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
