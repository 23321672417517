import Vue from 'vue'

window.globalData = new Vue({
  data: {
    isLoadingOverlayVisible: false
  }
})

Vue.mixin({
  computed: {
    $isLoadingOverlayVisible: {
      get: function () {
        return window.globalData.$data.isLoadingOverlayVisible
      }
    }
  },
  methods: {
    $hideLoadingOverlay () {
      window.globalData.$data.isLoadingOverlayVisible = false
    },
    $showLoadingOverlay () {
      window.globalData.$data.isLoadingOverlayVisible = true
    }
  }
})
