<template>
    <layout-logged-in-empty v-resize="resize" :hideScrollBar="hideScrollBar" class="flight-dashboard">
        <span v-if="show">
            <v-container class="content desktop-view" v-show="!isMobileView">
                <v-layout row>
                    <v-flex class="xl5 lg5 md5 sm5 xs5">
                        <v-alert type="success" class="mr-2 arrivals" icon="ARRIVALS">ARRIVALS</v-alert>
                    </v-flex>
                    <v-flex class="xl2 lg2 md2 sm2 xs2">
                        <v-alert type="warning" class="mr-2 time" icon="12:00">
                            <Clock @timeChanged="timeChanged" :time="serverTime"></Clock></v-alert>
                    </v-flex>
                    <v-flex class="xl5 lg5 md5 sm5 xs5">
                        <v-alert class="departures" type="info" icon="DEPARTURES">DEPARTURES</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout row>
                   <v-flex class="md6">
                       <span v-for="(importFlight, index) in importFlights" v-bind:key="index">
                           <FlightItem v-model="clockTime" :flight="importFlight" type="import"></FlightItem>
                       </span>
                   </v-flex>
                    <v-flex class="md6">
                        <span v-for="(exportFlight, index) in exportFlights" v-bind:key="index">
                           <FlightItem v-model="clockTime" :flight="exportFlight" :isDeparturesColumn="true" type="export"></FlightItem>
                       </span>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-container class="content mobile-view" v-show="isMobileView">
                <v-layout row>
                    <v-flex class="xs12">
                        <v-alert type="warning" class="time" icon="12:00">
                            <Clock @timeChanged="timeChanged" :time="serverTime"></Clock>
                        </v-alert>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="xs12">
                        <v-alert type="success" class="arrivals" icon="ARRIVALS">ARRIVALS</v-alert>
                    </v-flex>
                    <v-flex class="xs12">
                         <span v-for="(importFlight, index) in importFlights" v-bind:key="index">
                            <FlightItem v-model="clockTime" :flight="importFlight" :isMobileView="isMobileView"
                                        type="import"></FlightItem>
                        </span>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="xs12">
                        <v-alert class="departures" type="info"
                                 icon="DEPARTURES">DEPARTURES</v-alert>
                    </v-flex>
                    <v-flex class="xs12">
                        <span v-for="(exportFlight, index) in exportFlights" v-bind:key="index">
                           <FlightItem v-model="clockTime" :flight="exportFlight" :isMobileView="isMobileView"
                                       type="export"></FlightItem>
                        </span>
                    </v-flex>
                </v-layout>
            </v-container>
        </span>
    </layout-logged-in-empty>
</template>

<script>

import LayoutLoggedInEmpty from '@/components/Layout/LoggedInEmpty'
import FlightItem from '@/applications/Dashboard/components/Item'
import QStepApi from '@/libraries/Api/QStep/Api'
import moment from 'moment'
import Clock from '@/components/Core/Clock'

const TOP_BAR_HEIGHT = 116
const MIN_NON_SCROLLABLE_HEIGHT = 1080
const MIN_DESKTOP_WIDTH = 960
const MIN_NON_SCROLLABLE_WIDTH = 1450
const DEFAULT_NON_SCROLLABLE_ROW_COUNT = 11

export default {
  components: {
    LayoutLoggedInEmpty, FlightItem, Clock
  },
  name: 'FlightDashboard',
  data () {
    return {
      hideScrollBar: true,
      isLoadingData: false,
      show: false,
      serverTime: null,
      clockTime: null,
      importFlights: [],
      exportFlights: [],
      isMobileView: false
    }
  },
  mounted () {
    if (!this.isLoadingData) {
      this.$showLoadingOverlay()
      this.getData()
    }

    this.updateMobileView()
    this.updateScrollable()

    const _self = this
    setInterval(function () {
      if (!_self.isLoadingData) {
        _self.getData()
      }
    }, 30000)
  },
  methods: {
    updateMobileView () {
      this.isMobileView = window.innerWidth < MIN_DESKTOP_WIDTH
    },
    updateScrollable () {
      this.hideScrollBar = window.innerHeight >= MIN_NON_SCROLLABLE_HEIGHT && window.innerWidth >= MIN_NON_SCROLLABLE_WIDTH
    },
    isScrollable () {
      return !this.hideScrollBar
    },
    getItemsContainerHeight () {
      return window.innerHeight - TOP_BAR_HEIGHT
    },
    getDefaultItemHeight () {
      return (MIN_NON_SCROLLABLE_HEIGHT - TOP_BAR_HEIGHT) / DEFAULT_NON_SCROLLABLE_ROW_COUNT
    },
    resize () {
      this.updateMobileView()
      this.updateScrollable()
    },
    timeChanged (clockTime) {
      this.clockTime = clockTime
    },
    getData: function () {
      this.isLoadingData = true
      const _self = this
      const qStepApi = new QStepApi()
      qStepApi.getFlightDashboard(this.getLimit())
        .then(response => {
          if (this.lodash.get(response.data.data, 'clockTime') !== undefined) {
            _self.serverTime = new Date(response.data.data.clockTime)
            _self.displayTime = new Date(response.data.data.clockTime)

            if (_self.clockTime === null) {
              _self.clockTime = new Date(response.data.data.clockTime)
            }
          }

          if (response.data.data.import !== undefined) {
            _self.importFlights = response.data.data.import
          }

          if (response.data.data.export !== undefined) {
            _self.exportFlights = response.data.data.export
          }

          _self.show = true
          this.isLoadingData = false

          this.$hideLoadingOverlay()
        }).catch(() => {
          this.isLoadingData = false
          this.$hideLoadingOverlay()
        })
    },
    getLimit () {
      if (!this.isScrollable()) {
        return Math.floor(this.getItemsContainerHeight() / this.getDefaultItemHeight())
      }

      return DEFAULT_NON_SCROLLABLE_ROW_COUNT
    },
    getClockDisplayTime () {
      if (this.clockTime !== null) {
        return moment(this.clockTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
    .flight-dashboard {
        &.layout-main-wrapper {
            .layout-main, .layout-logged-in, .content {
                max-width: 100%;

                .container.page {
                    max-width: 100%;

                    .desktop-view {
                        .arrivals {
                            text-align: left;
                        }

                        .departures {
                            text-align: right;
                        }
                    }

                    .time {
                        text-align: center;
                    }

                    .v-alert__wrapper {
                        .v-alert__content {
                            font-size: 30px;
                        }

                        i {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
