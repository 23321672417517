import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    clientCredentialsLoggedIn: false,
    accessToken: '',
    tokenType: '',
    admin: {
      name: '',
      truckNumber: '',
      truckList: [],
      route: ''
    },
    filter: {
      fromDate: '',
      toDate: '',
      truckNumber: '',
      locations: []
    },
    pickUp: {
      selectedCmrLiabilityIds: [],
      consignorSignatureData: {}
    },
    contactlessDelivery: {
      shipments: [],
      terminalId: ''
    },
    unsignedCount: 0,
    applicationCount: 0,
    breadcrumb: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/'
      },
      {
        text: 'QDrive',
        href: '/qdrive/truck-number'
      },
      {
        text: null,
        route: null,
        href: null
      }
    ]
  },
  plugins: [createPersistedState()],
  getters: {
    breadcrumb: state => {
      return state.breadcrumb
    },
    accessToken: state => {
      return state.accessToken
    },
    tokenType: state => {
      return state.tokenType
    },
    admin: state => {
      return state.admin
    },
    hasAdmin: state => {
      return state.admin.name !== ''
    },
    truckNumber: state => {
      return state.admin.truckNumber
    },
    truckList: state => {
      return state.admin.truckList
    },
    filter: state => {
      return state.filter
    },
    applicationCount: state => {
      return state.applicationCount
    },
    clientCredentialsLoggedIn: state => {
      return state.clientCredentialsLoggedIn
    },
    selectedCmrLiabilityIds: state => {
      return state.pickUp.selectedCmrLiabilityIds
    },
    consignorSignatureData: state => {
      return state.pickUp.consignorSignatureData
    },
    contactlessDeliveryData: state => {
      return state.contactlessDelivery
    },
    unsignedCount: state => {
      return state.unsignedCount
    }
  },
  mutations: {
    login (state, data) {
      state.loggedIn = true
      state.accessToken = data.access_token
      state.tokenType = data.token_type
    },
    clientCredentialsLogin (state, data) {
      state.clientCredentialsLoggedIn = true
      state.accessToken = data.access_token
      state.tokenType = data.token_type
    },
    logout (state) {
      state.loggedIn = false
      state.clientCredentialsLoggedIn = false
      state.accessToken = ''
      state.tokenType = ''
      state.admin.name = ''
      state.admin.truckNumber = []
      state.filter.fromDate = ''
      state.filter.toDate = ''
      state.filter.truckNumber = ''
      state.filter.locations = ''
      state.pickUp.selectedCmrLiabilityIds = []
      state.applicationCount = 0
    },
    admin (state, data) {
      state.admin.name = data.data.name
    },
    truckNumber (state, truckNumber) {
      state.admin.truckNumber = truckNumber
    },
    truckList (state, truckList) {
      state.admin.truckList = truckList
    },
    filter (state, data) {
      if (data.fromDate !== undefined) {
        state.filter.fromDate = data.fromDate
      }

      if (data.toDate !== undefined) {
        state.filter.toDate = data.toDate
      }

      if (data.truckNumber !== undefined) {
        state.filter.truckNumber = data.truckNumber
      }

      if (data.locations !== undefined) {
        state.filter.locations = data.locations
      }
    },
    applicationCount (state, count) {
      state.applicationCount = count
    },
    selectedCmrLiabilityIds (state, data) {
      state.pickUp.selectedCmrLiabilityIds = data
    },
    consignorSignatureData (state, data) {
      state.pickUp.consignorSignatureData = data
    },
    contactlessDeliveryShipments (state, data) {
      state.contactlessDelivery.shipments = data
    },
    contactlessDeliveryTerminalId (state, data) {
      state.contactlessDelivery.terminalId = data
    },
    unsignedCount (state, data) {
      state.unsignedCount = data
    },
    breadcrumb (state, data) {
      state.breadcrumb[2] = data
    }
  }
})
