<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    if (navigator.userAgent.indexOf('uiType=television') !== -1) {
      const viewport = document.querySelector('meta[name=viewport]')
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale =1.0, user-scalable=0')
    }
  }
}
</script>

<style lang="scss" scoped>
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #ffffff;
        /*margin-top: 20px;*/
        /*margin-left: 10px;*/
        /*margin-right: 10px;*/
    }
</style>
