<template>
    <div class="kpi">
        <div class="kpi-inner">
            <span class="kpi-label">KPI</span> <span
                :class="{'kpi-value': true, 'kpi-in-handling': isInHandling(), 'kpi-is-handled-on-time': isHandledOnTime(), 'kpi-is-not-handled-on-time': !isHandledOnTime()}">
        {{ kpiTime }} <span v-show="isConditionUsed()">!</span>
    </span>
        </div>
    </div>
</template>

<script>

import moment from 'moment'

export default {
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  name: 'Kpi',
  data () {
    return {
      kpiTime: moment(this.kpi.kpiTime).format('HH:mm')
    }
  },
  methods: {
    isInHandling () {
      return this.kpi.isInHandling
    },
    isHandledOnTime () {
      return this.kpi.isHandledOnTime
    },
    isConditionUsed () {
      return this.kpi.isConditionUsed
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
    .kpi {
        .kpi-label {
            font-size: 11px;
        }

        .kpi-value {
            &.kpi-is-handled-on-time {
                color: #4caf50;

                &.kpi-in-handling {
                    color: #000000;
                }
            }

            &.kpi-is-not-handled-on-time {
                color: #ff5252;
            }
        }
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
