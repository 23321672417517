<template>
    <v-card :class="getClasses()">
        <v-card-title class="title pt-2 pb-5">
            <v-container class="pt-0 pb-0">
                <v-layout row class="title-inner">
                    <StaticProgressBar v-show="isImport() && isInHandling()" width="250px" padding="0px" height="5px"
                                       :progressPercentage="getProgressPercentage()">
                    </StaticProgressBar>
                    <v-flex class="md8 left-title">
                        <font-awesome-icon class="fa-lg icon" :icon="icon()"/>
                        <span class="ccfn">{{ getFlightLabel() }}</span>
                        <span class="time-label">{{ getTimeLabel() }}</span>
                        <span class="display-time"> {{ getDisplayTimeFormat }}</span>
                    </v-flex>
                    <v-flex class="md4 right-title">
                        <span v-if="isFutureTimeDifference()">
                            <span class="time-label">{{ getTimeLabel() }}</span>
                            {{ differenceTimeFormat }}
                        </span>
                        <span v-if="isPastTimeDifference()">
                            <span class="time-label">{{ getTimeLabel() }}</span>
                            {{ differenceTimeFormat }} ago
                        </span>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-title>
        <v-card-text class="details pb-2">
            <v-container class="pb-2 pt-0">
                <v-layout row>
                    <v-flex class="xl12 lg12 md12">
                        <span class="manifested-pieces">
                            {{ flight.manifestedPieces }} <span v-if="flight.manifestedPieces !== 1">pcs</span>
                            <span v-else>pc</span>
                        </span> /
                        <span class="shipments-weight">
                            {{ flight.weight }} <span v-if="flight.weight !== 1">kgs</span>
                            <span v-else>kg</span>
                        </span>
                        <span class="shc-codes">
                            <span v-if="hasShcCodes()">
                                / SHC:
                                <span v-for="(shcCode, index) in flight.shcCodes" v-bind:key="index">
                                   {{ shcCode }}
                                </span>
                            </span>
                        </span>
                        <span v-show="hasMailBags()" class="mail-bags">
                            Mail:
                             <span>{{ getMailBagsCount() }} <span
                                     v-if="getMailBagsCount() !== 1">bags</span>
                                <span v-else>bag</span>
                             </span> /
                            <span>{{ flight.mailWeight }} <span
                                    v-if="flight.mailWeight !== 1">kgs</span>
                                <span v-else>kg</span>
                            </span>
                        </span>
                        <span v-if="hasKpi()" class="kpi-time">
                            <Kpi :kpi="flight.kpi"></Kpi>
                        </span>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>

import moment from 'moment'
import StaticProgressBar from '@/components/Core/Progress/StaticProgressBar'
import Kpi from '@/applications/Dashboard/components/Kpi'

export default {
  components: {
    StaticProgressBar, Kpi
  },
  props: {
    flight: {
      type: Object,
      required: true
    },
    currentDate: {
      type: Date,
      required: true
    },
    type: {
      type: String,
      require: true
    },
    isMobileView: {
      type: Boolean,
      default: false
    },
    isDeparturesColumn: {
      type: Boolean,
      default: false
    }
  },
  name: 'Item',
  model: {
    prop: 'currentDate',
    event: 'changed'
  },
  data () {
    return {
      differenceTimeFormat: this.getDifferenceTimeFormat()
    }
  },
  computed: {
    getDisplayTimeFormat () {
      if (this.hasDisplayTime()) {
        return this.getDisplayTime().format('HH:mm')
      }

      return ''
    }
  },
  methods: {
    hasKpi () {
      return this.lodash.get(this.flight, 'kpi') !== null
    },
    getProgressPercentage () {
      if (this.isImport()) {
        return 100 * this.flight.actualPieces / this.flight.manifestedPieces
      }
      return 0
    },
    isImport () {
      return this.type === 'import'
    },
    isExport () {
      return this.type === 'export'
    },
    icon () {
      if (this.flight.type === 'rfs') {
        return 'truck'
      } else {
        return 'plane'
      }
    },
    getDifferenceTimeFormat () {
      if (this.hasTimeDifference()) {
        const minutesInDay = 24 * 60
        const days = Math.floor(this.getTimeDifference() / minutesInDay)
        const hours = Math.floor((this.getTimeDifference() - days * minutesInDay) / 60)
        const minutes = this.getTimeDifference() - days * minutesInDay - hours * 60

        const timeFormat = []
        if (days !== 0) {
          timeFormat.push(days + 'd')
        }

        if (hours !== 0 || (days !== 0 && hours === 0)) {
          timeFormat.push(hours + 'h')
        }

        timeFormat.push(minutes + 'm')

        return timeFormat.join(' ')
      }

      return ''
    },
    hasShcCodes () {
      return this.flight.shcCodes.length > 0
    },
    getDisplayTime () {
      let displayDateTime = null

      if (this.flight.nextRelevantTime === 'ata' && this.lodash.get(this.flight, 'ata') !== null) {
        displayDateTime = this.flight.ata
      }

      if (this.flight.nextRelevantTime === 'eta' && this.lodash.get(this.flight, 'eta') !== null) {
        displayDateTime = this.flight.eta
      }

      if (this.flight.nextRelevantTime === 'sta' && this.lodash.get(this.flight, 'sta') !== null) {
        displayDateTime = this.flight.sta
      }

      if (this.flight.nextRelevantTime === 'atd' && this.lodash.get(this.flight, 'atd') !== null) {
        displayDateTime = this.flight.atd
      }

      if (this.flight.nextRelevantTime === 'etd' && this.lodash.get(this.flight, 'etd') !== null) {
        displayDateTime = this.flight.etd
      }
      if (this.flight.nextRelevantTime === 'std' && this.lodash.get(this.flight, 'std') !== null) {
        displayDateTime = this.flight.std
      }

      if (this.flight.nextRelevantTime === 'block' && this.lodash.get(this.flight, 'blockDateTime') !== null) {
        displayDateTime = this.flight.blockDateTime
      }

      if (this.flight.nextRelevantTime === 'runway' && this.lodash.get(this.flight, 'runwayDateTime') !== null) {
        displayDateTime = this.flight.runwayDateTime
      }

      if (displayDateTime !== null) {
        return moment(displayDateTime, 'YYYY-MM-DD HH:mm:ss Z')
      }

      return null
    },
    hasDisplayTime () {
      return this.getDisplayTime() !== null
    },
    getTimeDifference () {
      if (this.hasDisplayTime()) {
        return Math.abs(moment(this.currentDate).diff(this.getDisplayTime(), 'minutes'))
      } else {
        return ''
      }
    },
    hasTimeDifference () {
      return this.getTimeDifference() !== ''
    },
    isFutureTimeDifference () {
      return this.hasDisplayTime() && moment(this.currentDate) <= this.getDisplayTime()
    },

    isPastTimeDifference () {
      return this.hasDisplayTime() && moment(this.currentDate) > this.getDisplayTime()
    },
    getTimeLabel () {
      if (this.flight.nextRelevantTime === 'ata') {
        return 'ATA'
      }

      if (this.flight.nextRelevantTime === 'eta') {
        return 'ETA'
      }

      if (this.flight.nextRelevantTime === 'sta') {
        return 'STA'
      }

      if (this.flight.nextRelevantTime === 'atd') {
        return 'ATD'
      }

      if (this.flight.nextRelevantTime === 'etd') {
        return 'ETD'
      }

      if (this.flight.nextRelevantTime === 'std') {
        return 'STD'
      }

      if (this.flight.nextRelevantTime === 'block') {
        return 'BLOCK'
      }

      if (this.flight.nextRelevantTime === 'runway') {
        return 'RUNWAY'
      }

      return 'N/A'
    },
    getFlightLabel () {
      let flightLabel = this.flight.ccfn
      if (this.lodash.get(this.flight, 'std') !== null) {
        flightLabel += '/' + moment(this.flight.std, 'YYYY-MM-DD HH:mm:ss Z').format('DDMMMYY').toUpperCase()
      }

      return flightLabel
    },
    getStatusIndicatorClass () {
      const borderIndicatorClass = 'border-indicator'
      if (this.isHandledBeforeDeparture()) {
        return 'handled-before-departure'
      } else if (this.flight.isHandled || this.isCustomsUnloadingFinished()) {
        return 'handled'
      } else if (this.isUrgent()) {
        return 'urgent'
      } else if (this.isInHandling()) {
        return borderIndicatorClass + ' is-in-handling'
      } else if (this.isArrived()) {
        return borderIndicatorClass + ' arrived'
      }

      if (this.isExport()) {
        if (this.isDepartureIn60()) {
          return borderIndicatorClass + ' departure-in-60'
        } else if (this.isDepartureIn120()) {
          return borderIndicatorClass + ' departure-in-120'
        } else if (!this.isDepartureIn120()) {
          return borderIndicatorClass + ' departure-after-120'
        }
      }

      return borderIndicatorClass + ' not-arrived'
    },
    isUrgent () {
      return this.isExport() &&
        !this.flight.isHandled &&
        this.currentDate > this.getDisplayTime()
    },
    isInHandling () {
      return this.flight.isInHandling
    },
    isArrived () {
      return this.isImport() && this.flight.isArrived
    },
    isRfs () {
      return this.flight.type === 'rfs'
    },
    isCustomsUnloadingFinished () {
      return this.isImport() && this.isRfs() && this.flight.isCustomsUnloadingFinished
    },
    isDepartureIn60 () {
      return this.isExport() && this.isDepartureWithin(this.currentDate, this.getDisplayTime(), 0, 60)
    },
    isDepartureIn120 () {
      return this.isExport() && this.isDepartureWithin(this.currentDate, this.getDisplayTime(), 0, 120)
    },
    isDepartureWithin (currentDate, departureDate, fromMinutes, toMinutes) {
      const fromDate = departureDate
      const toDate = departureDate

      if (fromDate !== null && toDate !== null) {
        fromDate.subtract(fromMinutes, 'minutes')
        toDate.subtract(toMinutes, 'minutes')
        return currentDate > fromDate && currentDate >= toDate
      }

      return false
    },
    isHandledBeforeDeparture () {
      return this.isExport() && this.flight.isHandled && !this.flight.isDeparted
    },
    getClasses () {
      const classes = []

      classes.push(this.getStatusIndicatorClass())

      if (!this.isDeparturesColumn && !this.isMobileView) {
        classes.push('mr-3')
      }

      classes.push('mb-3')
      classes.push('item')

      return classes
    },
    getMailBagsCount () {
      return this.flight.countMailBags
    },
    hasMailBags () {
      return this.getMailBagsCount() > 0
    }
  },
  watch: {
    currentDate: function () {
      this.differenceTimeFormat = this.getDifferenceTimeFormat()
    }
  }
}
</script>

<style lang="scss" scoped>
    .item {
        padding-left: 10px;

        &.urgent {
            background-color: #FFCDD2;
        }

        &.handled {
            background-color: #E5E5E5;
        }

        &.handled-before-departure {
            background-color: #81C784;
        }

        &.border-indicator {
            border-left-width: 10px;
            border-left-style: solid;
            padding-left: 0;

            &.arrived {
                border-left-color: #FFEB3B;
            }

            &.not-arrived {
                border-left-color: #ff5252;
            }

            &.is-in-handling {
                border-left-color: #81C784;
            }

            &.departure-in-60 {
                border-left-color: #ff5252;
            }

            &.departure-in-120 {
                border-left-color: #FFEB3B;
            }

            &.departure-after-120 {
                border-left-color: #81C784;
            }
        }

        .title {
            .ccfn {
                margin-right: 10px;
                font-weight: bold;
            }

            .time-label {
                font-size: 14px;
                margin-right: 5px;
            }

            .icon {
                color: rgba(0, 0, 0, .54);
                font-size: 14px;
                margin-right: 10px;
                position: relative;
                top: -3px;
            }

            .left-title {
                text-align: left;
            }

            .right-title {
                text-align: right;
            }

            .title-inner {
                position: relative;

                .static-progress-bar {
                    top: -4px;
                    position: absolute;
                }
            }
        }

        .details {
            text-align: left;

            .mail-bags {
                margin-left: 40px;
            }

            .kpi-time {
                display: inline-block;
                float: right;
                margin-top: -1px;
            }
        }
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
