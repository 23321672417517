import * as AppContactlessDelivery from './ContactlessDelivery'
import * as AppDashboard from './Dashboard'
import * as AppQdrive from './Qdrive'
import * as AppApplications from './Applications'
import * as AppAuth from './Auth'

export const applications = [
  AppContactlessDelivery,
  AppDashboard,
  AppQdrive,
  AppApplications,
  AppAuth
]
export default applications
