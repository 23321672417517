import AppDashboard from './AppDashboard'
import Flight from './components/Flight.vue'

export default [
  {
    path: '/dashboard',
    redirect: '/dashboard/flights',
    component: AppDashboard,
    children: [
      {
        path: 'flights',
        name: 'FlightDashboard',
        component: Flight
      }
    ]
  }
]
