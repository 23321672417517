import axios from 'axios'
import store from '@/store'
import router from '@/router'
import ApiError from './ApiError'
import Authentication from '@/libraries/Authentication/Authentication'
import Parameter from './Parameter'
import _ from 'underscore'

import createDebug from 'debug'

const debug = createDebug('api.js')

export default class Api {
  constructor (errorCallback = undefined) {
    this._errorCallback = errorCallback
    this.setupAxios()
    this.cancelSources = {}
  }

  setupAxios () {
    this.config = {
      baseURL: process.env.VUE_APP_CT_API_URL,
      headers: {
        Authorization: store.getters.tokenType + ' ' + store.getters.accessToken
      }
    }
    this.$axios = axios.create(this.config)
    this.setupAxiosProxies()
  }

  cancelToken () {
    return axios.CancelToken.source()
  }

  setupAxiosProxies () {
    const requestWrapper = (request) => request
      .catch(error => {
        if (error.response && error.response.status === 401) {
          store.commit('logout')
        }
        return Promise.reject(
          new ApiError(axios.isCancel(error) ? 'Request cancelled' : error.message, error)
        )
      })
      .then(response => {
        if (typeof response.data !== 'object') {
          return Promise.reject(new ApiError('Invalid API response', null, response))
        }

        const apiResponse = _.defaults(response?.data || {}, {
          $reponse: response,
          success: false,
          message: '',
          data: {}
        })

        if (apiResponse.success === false) {
          debug('unsuccessful request', apiResponse)
        }

        return apiResponse.success
          ? Promise.resolve(apiResponse)
          : Promise.reject(
            new ApiError(apiResponse.message, null, response)
          )
      })

    _.extend(this, {
      request: _.compose(requestWrapper, this.$axios),
      get: _.compose(requestWrapper, this.$axios.get),
      delete: _.compose(requestWrapper, this.$axios.delete),
      head: _.compose(requestWrapper, this.$axios.head),
      options: _.compose(requestWrapper, this.$axios.options),
      post: _.compose(requestWrapper, this.$axios.post),
      put: _.compose(requestWrapper, this.$axios.put),
      patch: _.compose(requestWrapper, this.$axios.patch)
    })
  }

  authenticate (credentials) {
    const _self = this
    return this.$axios.post('/oauth/token', {
      username: credentials.username,
      password: credentials.password,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: 'password'
    }).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  clientCredentialsAuthenticate () {
    const _self = this

    return this.$axios.post('/oauth/token', {
      client_id: process.env.VUE_APP_CLIENT_CREDENTIAL_ID,
      client_secret: process.env.VUE_APP_CLIENT_CREDENTIAL_SECRET,
      grant_type: 'client_credentials'
    }).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCarItineraries (carItineraryFilter, encryptedData = null) {
    const _self = this
    const parameters = []

    carItineraryFilter.getParameters().forEach(function (parameter) {
      parameters.push(new Parameter(parameter.getName(), parameter.getValue()))
    })
    parameters.push(new Parameter('columns', carItineraryFilter.getColumns().join()))

    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries', encryptedData, parameters)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCarItinerariesSealStatus (carItineraryFilter, encryptedData = null) {
    const _self = this
    const parameters = []

    carItineraryFilter.getParameters().forEach(function (parameter) {
      parameters.push(new Parameter(parameter.getName(), parameter.getValue()))
    })

    return this.$axios.get(
      this.buildUrl('/v1/compartments/statuses', encryptedData, parameters)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCarListByCode (code, type, encryptedData = null) {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/vehicles/' + type + '?registrationNumber=' + code, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCarItinerary (id, encryptedData = null) {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/' + id, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  cancelPickUp (id, encryptedData = null) {
    const _self = this

    return this.$axios.delete(
      this.buildUrl('/v1/car-itineraries/' + id + '/pick-up', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  breakSeal (item, encryptedData = null) {
    const _self = this
    return this.$axios.delete(
      this.buildUrl('/v1/compartments/' + item.compartment_id + '/seals/' + item.id, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  deleteSeal (item, encryptedData = null) {
    const _self = this
    return this.$axios.delete(
      this.buildUrl('/v1/seal/' + item.id, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  updateUnloaded (id, encryptedData = null) {
    const _self = this

    return this.$axios.put(
      this.buildUrl('/v1/car-itineraries/' + id + '/unloaded', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  deleteUnloaded (id, encryptedData = null) {
    const _self = this

    return this.$axios.delete(
      this.buildUrl('/v1/car-itineraries/' + id + '/unloaded', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getPickUpDetails (id, encryptedData = null) {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/' + id, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getShcDetails (id, encryptedData = null) {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/cars/' + id, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  setNoticeRead (id, signature, encryptedData = null) {
    const _self = this
    return this.$axios.put(
      this.buildUrl('/v1/car-itineraries/' + id + '/notice-read/' + signature, encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getSeals (compartmentFilter, encryptedData = null) {
    const _self = this
    const parameters = []

    compartmentFilter.getParameters().forEach(function (parameter) {
      parameters.push(new Parameter(parameter.getName(), parameter.getValue()))
    })

    return this.$axios.get(
      this.buildUrl('/v1/compartments', encryptedData, parameters)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  addSeal (code, compartmentID, encryptedData = null) {
    const _self = this
    return this.$axios.post(
      this.buildUrl('/v1/compartments/' + compartmentID + '/seals', encryptedData), {
        code
      }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  confirmCompartment (item, truckNumber, encryptedData = null) {
    const _self = this
    const url = '/v1/compartments/' + item.id + '/confirmed?truckNumber=' + truckNumber
    if (!item.sealed_at) {
      return this.$axios.post(
        this.buildUrl(url, encryptedData)).then(response => {
        return _self.handleThen(response)
      }).catch(error => {
        return _self.handleCatch(error)
      })
    } else {
      return this.$axios.delete(
        this.buildUrl(url, encryptedData)).then(response => {
        return _self.handleThen(response)
      }).catch(error => {
        return _self.handleCatch(error)
      })
    }
  }

  getIatas () {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/iatas')
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  searchIata (searchQuery, cancelSource = null, previousCancelSource = null) {
    const _self = this
    if (previousCancelSource !== null) {
      previousCancelSource.cancel()
    }

    if (cancelSource !== null) {
      this.config.cancelToken = cancelSource.token
    }

    return this.$axios.get(
      this.buildUrl('/v1/airports/search', null, [new Parameter('s', searchQuery)])
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getAdmin () {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/admin')
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCmr (carItineraryId, encryptedData = null) {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/' + carItineraryId + '/cmr', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getPublicCmr (carItineraryId, hash) {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/' + carItineraryId + '/cmr/' +  hash)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getPublicCmrEncrypted (carItineraryId, encryptedData = null) {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/cmr/' + carItineraryId , encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  signDelivery (signatureData, encryptedData = null) {
    const _self = this
    return this.$axios.post(
      this.buildUrl('/v1/car-itineraries/' + signatureData.carItineraryId + '/sign-delivery', encryptedData), {
        consignee: signatureData.name,
        signature: signatureData.signature,
        extension: signatureData.extension,
        notes: signatureData.notes,
        longitude: signatureData.longitude,
        latitude: signatureData.latitude,
        signedOn: signatureData.signedOn
      }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  pickUp (consignorSignatureData, driverSignatureData = {}, selectedCmrLiabilityIds = [], encryptedData = null) {
    const _self = this
    return this.$axios.post(
      this.buildUrl('/v1/car-itineraries/' + consignorSignatureData.carItineraryId + '/pick-up', encryptedData), {
        consignorSignature: {
          continueWithoutSignature: consignorSignatureData.continueWithoutSignature,
          name: consignorSignatureData.name,
          signature: consignorSignatureData.signature,
          extension: consignorSignatureData.extension,
          notes: consignorSignatureData.notes,
          longitude: consignorSignatureData.longitude,
          latitude: consignorSignatureData.latitude,
          signedOn: consignorSignatureData.signedOn
        },
        driverSignature: {
          name: driverSignatureData.name,
          signature: driverSignatureData.signature,
          extension: driverSignatureData.extension,
          notes: driverSignatureData.notes,
          longitude: driverSignatureData.longitude,
          latitude: driverSignatureData.latitude,
          signedOn: driverSignatureData.signedOn
        },
        selectedCmrLiabilityIds: selectedCmrLiabilityIds
      }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  takeSignatureLater (consignorSignatureData, driverSignatureData = {}, selectedCmrLiabilityIds = [], encryptedData = null) {
    const _self = this
    return this.$axios.post(
      this.buildUrl('/v1/car-itineraries/' + consignorSignatureData.carItineraryId + '/pick-up', encryptedData), {
        consignorSignature: {
          continueWithoutSignature: consignorSignatureData.continueWithoutSignature,
          name: consignorSignatureData.name,
          signature: consignorSignatureData.signature,
          extension: consignorSignatureData.extension,
          notes: consignorSignatureData.notes,
          longitude: consignorSignatureData.longitude,
          latitude: consignorSignatureData.latitude,
          signedOn: consignorSignatureData.signedOn
        },
        driverSignature: {
          name: driverSignatureData.name,
          signature: driverSignatureData.signature,
          extension: driverSignatureData.extension,
          notes: driverSignatureData.notes,
          longitude: driverSignatureData.longitude,
          latitude: driverSignatureData.latitude,
          signedOn: driverSignatureData.signedOn
        },
        takeSignatureLater: true,
        selectedCmrLiabilityIds: selectedCmrLiabilityIds
      }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  bulkSign (consignorSignatureData, driverSignatureData = {}, selectedCmrLiabilityIds = [], encryptedData = null) {
    const _self = this
    return this.$axios.post(
      this.buildUrl('/v1/car-itineraries/sign-bulk', encryptedData), {
        consignorSignature: {
          continueWithoutSignature: consignorSignatureData.continueWithoutSignature,
          name: consignorSignatureData.name,
          signature: consignorSignatureData.signature,
          extension: consignorSignatureData.extension,
          notes: consignorSignatureData.notes,
          signedOn: consignorSignatureData.signedOn
        },
        driverSignature: {
          name: driverSignatureData.name,
          signature: driverSignatureData.signature,
          extension: driverSignatureData.extension,
          notes: driverSignatureData.notes,
          signedOn: driverSignatureData.signedOn
        },
        takeSignatureLater: consignorSignatureData.takeSignatureLater,
        selectedCmrLiabilityIds: selectedCmrLiabilityIds,
        truckNumber: consignorSignatureData.truckNumber
      }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getApplications () {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/applications')
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCarItineraryLocation (id, encryptedData = null) {
    const _self = this
    return this.$axios.get(
      this.buildUrl('/v1/car-itineraries/' + id + '/location', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getFlightDashboard (limit) {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/flight-dashboard/' + limit)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  getCmrLiabilities (encryptedData = null) {
    const _self = this

    return this.$axios.get(
      this.buildUrl('/v1/cmr/liabilities', encryptedData)
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  updateCarShipmentDetails (carId, shipmentDetails) {
    const _self = this

    return this.$axios.patch(
      this.buildUrl('/v1/cars/' + carId + '/shipments'), shipmentDetails
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  contactlessAcceptDelivery (signatureData, name, shipmentIds, terminalId, isSingleRequest = true, encryptedData = null) {
    const _self = this

    if (isSingleRequest) {
      if (this.cancelSources.contactlessAcceptDelivery !== undefined) {
        this.cancelSources.contactlessAcceptDelivery.cancel()
      }
    }

    this.cancelSources.contactlessAcceptDelivery = this.cancelToken()

    return this.post(
      this.buildUrl('/v1/delivery/accept', encryptedData), {
        signature: signatureData.signature,
        extension: signatureData.extension,
        name: name,
        shipmentIds: shipmentIds,
        terminalId: terminalId
      },
      { cancelToken: this.cancelSources.contactlessAcceptDelivery.token }
    ).then(response => {
      return _self.handleThen(response)
    }).catch(error => {
      return _self.handleCatch(error)
    })
  }

  handleThen (response) {
    return response
  }

  handleCatch (error) {
    this.handleError(error)
    return Promise.reject(error)
  }

  handleError (error) {
    if (this._errorCallback) {
      this._errorCallback(error)
      return
    }
    if (error.response.status === 404) {
      router.push({ path: '/404' })
    } else if (error.response.status === 412) {
      router.push({ name: '412' })
    } else if (error.response.status === 419) {
      router.push({ name: '419' })
    }

    const authentication = new Authentication()
    if (authentication.isUnauthorized(error)) {
      authentication.unauthorized()
    }
  }

  buildUrl (path, encryptedData = null, parameters = []) {
    let url = path

    if (encryptedData !== null) {
      parameters.push(new Parameter('e', encryptedData))
    }

    if (parameters.length > 0) {
      url += '?' + parameters.map(function (parameter) {
        return parameter.getName() + '=' + parameter.getValue()
      }).join('&')
    }

    return url
  }
}
