import AppContactlessDelivery from './AppContactlessDelivery'

export default [
  {
    path: '/contactless-delivery',
    component: AppContactlessDelivery,
    children: [
      {
        path: ':terminal_id',
        name: 'Contactless delivery',
        component: () => import(/* webpackChunkName: "application-contactless-delivery" */
          './components/Splash'
        ),
        meta: {
          requiresAuth: false,
          requiresClientCredentialsAuth: true,
          appBar: false
        }
      },
      {
        path: ':terminal_id/start',
        name: 'Start contactless delivery',
        component: () => import(/* webpackChunkName: "application-contactless-delivery" */
          './components/Stepper'
        ),
        meta: {
          requiresAuth: false,
          requiresClientCredentialsAuth: true
        }
      }
    ]
  }
]
