import AppApplications from './AppApplications'
import Applications from './components/Applications'
import store from '@/store'

export default [
  {
    path: '/',
    redirect: to => ({
      name: store.state.loggedIn
        ? 'Applications'
        : 'Login'
    })
  },
  {
    path: '/applications',
    redirect: '/applications/dashboard',
    component: AppApplications,
    children: [
      {
        path: 'dashboard',
        name: 'Applications',
        component: Applications
      }
    ]
  }
]
