import App from './AppAuth'
import Login from './components/Login'

export default [
  {
    path: '/auth',
    redirect: '/auth/login',
    component: App,
    children: [
      {
        path: 'login',
        name: 'Login',
        alias: '/login',
        component: Login,
        props: true,
        meta: { requiresAuth: false }
      }
    ]
  }
]
