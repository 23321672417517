<template>
    <v-app>
        <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
                <v-flex>
                    <LayoutMain>
                        <div class="login">
                            <v-form @submit.prevent="login">
                                <v-container>
                                    <v-flex>
                                        <Failures v-model="errorMessagesD"></Failures>
                                    </v-flex>
                                    <v-flex>
                                        <TextInput :required="true" :indicateRequired="false" id="form-username" label="Username" v-model="username" autocomplete="username"></TextInput>
                                    </v-flex>
                                    <v-flex>
                                        <PasswordInput :required="true" id="form-password" label="Password" v-model="password" autocomplete="current-password"></PasswordInput>
                                    </v-flex>
                                    <v-flex>
                                        <v-btn type="submit" color="primary">Login</v-btn>
                                    </v-flex>
                                </v-container>
                            </v-form>
                        </div>
                    </LayoutMain>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>

import Authentication from '@/libraries/Authentication/Authentication'
import LayoutMain from '@/components/Layout/Main'
import TextInput from '@/components/Core/Input/Text'
import PasswordInput from '@/components/Core/Input/Password'
import Failures from '@/components/Core/Message/Failures'

export default {
  components: {
    LayoutMain, TextInput, PasswordInput, Failures
  },
  name: 'Login',
  props: {
    errorMessages: {
      type: Array,
      default: function () { return [] }
    }
  },
  data () {
    return {
      errorMessagesD: this.errorMessages,
      username: '',
      password: ''
    }
  },
  methods: {

    login: function () {
      const _self = this
      this.$showLoadingOverlay()
      const authentication = new Authentication()

      authentication.login({ username: this.username, password: this.password }).then(() => {
        this.$hideLoadingOverlay()
      }).catch(() => {
        _self.errorMessagesD = ['Authentication failed']
        this.$hideLoadingOverlay()
      })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .login {
        .error-messages {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
</style>
