import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import '@/assets/css/theme/default.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileSignature, faPlane, faTruck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from '@/store'
import '@/global'
import 'vuetify/dist/vuetify.min.css'
import lodash from 'lodash'
import applications from './applications'
import createDebug from 'debug'
import * as VueComponents from './components/Vue'
import VueSignaturePad from 'vue-signature-pad'

const debug = createDebug('app:main')

console.debug = function (message, ...args) {
  const styles = {
    prefix: 'color: #000; background: #f8f9fa; padding: 1px; border-radius: 3px 0 0 3px;',
    suffix: 'color: #fff; background: #444; padding: 1px; border-radius: 0 3px 3px 0;',
    clear: 'background: transparent'
  }

  const logMessage = []

  if (typeof args[0] === 'string') {
    logMessage.push(
      `%c ${message} %c ${args.splice(0, 1)} `,
      styles.prefix,
      styles.suffix
    )
  } else {
    logMessage.push(
      `%c ${message} `,
      styles.suffix + ' border-radius: 3px;'
    )
  }

  logMessage.push(...args)
  return console.log(...logMessage)
}
if (module.hot) {
  let hotReloadCount = 0
  module.hot.addStatusHandler(status => {
    if (status === 'prepare') {
      console.clear()
      console.log(
        `%c Hot reload #${++hotReloadCount} %c ${(new Date()).toLocaleTimeString('et')} %c`,
        'color: #000; background: #ffc107; padding: 1px; border-radius: 3px 0 0 3px;',
        'color: #fff; background: #444; padding: 1px; border-radius: 0 3px 3px 0;',
        'background: transparent'
      )
    }
  })
}

Vue.prototype._ = lodash
Vue.prototype.lodash = lodash

Vue.use(VueSignaturePad)

Vue.use(Vuex)
Vue.config.productionTip = false

library.add(faFileSignature, faEnvelope, faPlane, faTruck)
Vue.component('font-awesome-icon', FontAwesomeIcon)

for (const key in VueComponents) {
  debug('adding component %s %O', key, VueComponents[key])
  Vue.component(key, VueComponents[key])
}

applications.forEach(app => {
  debug('adding application %O', app)
  if (typeof app.install === 'function') {
    Vue.use(app)
  }
  if (Array.isArray(app.routes || false)) {
    router.addRoutes(app.routes)
  }
})

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
