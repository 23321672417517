import axios from 'axios'

export default class ApiError extends Error {
  constructor (_message, _error = null, _response = null) {
    const response = _response || _error?.response || null
    const apiResponse = response?.data || {}

    super(apiResponse.message || _error?.message || _message)

    this.name = 'ApiError'
    this.error = _error
    this.response = response
    this.apiResponse = apiResponse
  }

  isCancel () {
    return axios.isCancel(this.error)
  }
}
