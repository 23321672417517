<template>
    <layout-logged-in :doneRendering="doneRendering" ref="layoutLoggedIn">
        <div class="applications">
            <h2>Applications</h2>
            <div v-if="showNoApplications">
                There no available applications for you to use!
            </div>
            <v-container >
                <v-layout row>
                    <v-flex v-for="application in applications" v-bind:key="application.slug" :class="{'list-style': $vuetify.breakpoint.smAndDown, 'application lx6 lg6 md6 sm12 xs12': true}">
                        <v-btn height="100px" @click="redirectToApplication(application)" outlined color="primary" dark>{{ application.name }}</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </layout-logged-in>
</template>

<script>

import applications from '@/applications'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import QStepApi from '@/libraries/Api/QStep/Api'
import _ from 'lodash'

export default {
  components: {
    LayoutLoggedIn
  },
  name: 'Applications',
  data: () => ({
    $api: null,
    doneRendering: false,
    showNoApplications: false,
    applications: []
  }),
  mounted () {
    this.$api = new QStepApi()
    this.fetchApplications()
  },
  methods: {
    fetchApplications () {
      this.$api.getApplications()
        .then(this.handleApplicationsResponse)
        .finally(() => {
          this.doneRendering = true
        })
    },
    handleApplicationsResponse (response) {
      this.applications = []
      if (response.data.data !== undefined) {
        response.data.data.forEach(app => {
          const application = _.find(applications, _app => _app.slug === app.slug)
          if (application !== undefined) {
            this.applications.push(
              Object.assign(app, application)
            )
          }
        })
        this.$refs.layoutLoggedIn.hideApplicationButton()
        this.handleDisplay()
      }

      this.$store.commit('applicationCount', this.applications.length)
    },
    redirectToApplication (application) {
      const defaultRoute = application.routes[0]
      this.$router.push(defaultRoute.redirect || defaultRoute.path)
    },
    handleDisplay () {
      if (this.applications.length === 1) {
        this.redirectToApplication(this.applications[0])
      } else if (this.applications.length === 0) {
        this.showNoApplications = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .applications {
        h2 {
            margin-bottom: 20px;
        }

        .application {
            button {
                margin: 1% 5%;
                width: 90%;
            }

            &.list-style {
                button {
                    width: 100%;
                    margin: 1% 0%;
                }
            }
        }
    }
</style>
