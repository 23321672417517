import AppQdrive from './AppQdrive'

export default [
  {
    path: '/qdrive',
    redirect: '/qdrive/truck-number',
    component: AppQdrive,
    children: [
      {
        path: 'truck-number',
        name: 'TruckNumber',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/TruckNumber')
      },
      {
        path: 'itinerary',
        name: 'CarItinerary',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Loaded')
      },
      {
        path: 'itinerary/filter',
        name: 'CarItineraryFilter',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Filter')
      },
      {
        path: 'itinerary/unsigned',
        name: 'CarItineraryUnsigned',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Unsigned')
      },
      {
        path: 'itinerary/loaded',
        name: 'CarItineraryLoaded',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Loaded')
      },
      {
        path: 'itinerary/seals',
        name: 'CarItinerarySeals',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/SealList')
      },
      {
        path: 'itinerary/:carItineraryId/details',
        name: 'CarItineraryDetails',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Details'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId)
          }
        }
      },
      {
        path: 'cmr/:carItineraryId',
        name: 'Cmr',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Cmr/Cmr'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId)
          }
        }
      },
      {
        path: 'itinerary/:carItineraryId/consignor-signature',
        name: 'ConsignorSignature',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/PickUp/ConsignorSignature'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId)
          }
        }
      },
      {
        path: 'itinerary/all/consignor-signature',
        name: 'ConsignorSignature-all',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/PickUp/ConsignorSignAll')
      },
      {
        path: 'itinerary/:carItineraryId/sign-delivery',
        name: 'SignDelivery',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Delivery/SignDelivery'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId)
          }
        }
      },
      {
        path: 'cmr/:carItineraryId/:hash',
        name: 'PublicCmr',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/Public/CarItinerary/Cmr'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            hash: route.params.hash,
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'cmr/:carItineraryId/public/:encryptedData',
        name: 'EncryptedPublicCmr',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/Public/CarItinerary/publicCmr'),
        props: (route) => {
          return {
            accessType: 'public',
            carItineraryId: Number(route.params.carItineraryId),
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/cmr-liabilities',
        name: 'CmrLiabilities',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Cmr/Liabilities'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId)
          }
        },
        meta: { requiresAuth: true }
      },
      {
        path: 'itinerary/all/consignor-signature/:encryptedData',
        name: 'EncryptedConsignorSignature-all',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/PickUp/ConsignorSignAll'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:encryptedData',
        name: 'EncryptedCarItinerary',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Filter'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/filter/:encryptedData',
        name: 'EncryptedCarItineraryFilter',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Filter'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/unsigned/:encryptedData',
        name: 'EncryptedCarItineraryUnsigned',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Unsigned'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/loaded/:encryptedData',
        name: 'EncryptedCarItineraryLoaded',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/pages/Loaded'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/seals/:encryptedData',
        name: 'EncryptedCarItinerarySeals',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/SealList'),
        props: (route) => {
          return {
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/details/:encryptedData',
        name: 'EncryptedCarItineraryDetails',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Details'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/consignor-signature/:encryptedData',
        name: 'EncryptedConsignorSignature',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/PickUp/ConsignorSignature'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/driver-signature/:encryptedData',
        name: 'EncryptedSignature',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/PickUp/DriverSignature'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/cmr-liabilities/:encryptedData',
        name: 'EncryptedCmrLiabilities',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Cmr/Liabilities'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'itinerary/:carItineraryId/sign-delivery/:encryptedData',
        name: 'EncryptedSignDelivery',
        component: () => import(/* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Delivery/SignDelivery'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      },
      {
        path: 'cmr/:carItineraryId/:encryptedData/driver',
        name: 'EncryptedCmr',
        component: () => import(
          /* webpackChunkName: "application-qdrive" */
          '@/components/CarItinerary/Cmr/Cmr'),
        props: (route) => {
          return {
            carItineraryId: Number(route.params.carItineraryId),
            accessType: 'public',
            encryptedData: route.params.encryptedData
          }
        },
        meta: { requiresClientCredentialsAuth: true, requiresAuth: false }
      }
    ]
  }
].map(parent => Object.assign(parent, {
  children: parent.children.map(child => Object.assign(child, {
    alias: '/' + child.path
  }))
}))
