<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
export default {
  name: 'AppDashboard'
}
</script>

<style scoped>
    #app {
        text-align: center;
    }
</style>
