<template>
    <v-app>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import screenfull from 'screenfull'

const metaDefaults = {
  appBar: true
}

export default {
  name: 'AppContactlessDelivery',
  data: () => ({
    appBar: true,
    drawer: false,
    isFullscreen: screenfull.isFullscreen
  }),
  computed: {
    screenfull: () => screenfull
  },
  created () {
    screenfull.onchange(event => {
      this.isFullscreen = screenfull.isFullscreen
    })
    this.routeChanged(this.$route, null)
    this.$router.beforeEach((to, from, next) => {
      this.routeChanged(to, from)
      next()
    })
  },
  methods: {
    routeChanged (route) {
      const routeMeta = Object.assign({ ...metaDefaults }, route.meta || {})

      this.appBar = routeMeta.appBar || true
    }
  }
}
</script>

<style>

</style>
